"use client";

import MultipleSelector from "@/components/ui/multiple-selector";
import { FC } from "react";

type sharedCategoriesSelectProps = {
  dbCategories: any;
  existingCategories: any;
  setNewDbCategories: any;
};

const sharedCategoriesSelect: FC<sharedCategoriesSelectProps> = ({
  dbCategories,
  existingCategories,
  setNewDbCategories,
}) => {
  const mockSearch = async (value: string): Promise<any[]> => {
    return new Promise((resolve) => {
      if (!value) {
        resolve(dbCategories);
      }
      const res = dbCategories.filter((option) => {
        return option.label.toLowerCase().includes(value.toLowerCase());
      });
      resolve(res);
    });
  };

  return (
    <MultipleSelector
      onSearch={async (value) => {
        const res = await mockSearch(value);
        return res;
      }}
      value={existingCategories}
      onChange={(e) => setNewDbCategories(e as Array<any>)}
      defaultOptions={dbCategories}
      placeholder="Select categories"
      loadingIndicator={
        <p className="py-2 text-center text-lg leading-10 text-muted-foreground">
          loading...
        </p>
      }
      emptyIndicator={
        <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
          no results found.
        </p>
      }
      hidePlaceholderWhenSelected
      inputProps={{
        className: "focus:ring-green-600 focus:border-green-600",
      }}
    />
  );
};

export default sharedCategoriesSelect;
