import(/* webpackMode: "eager" */ "/vercel/path0/components/main/header/navigations/main-desktop-navigation.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/main/header/navigations/main-mobile-navigation.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/shared-back-button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/shared-categories-select.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/shared-error.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/shared-pagination.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/shared-table-empty.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/tracking/MicrosoftClarity.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/theme-provider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/toaster.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/config/main/main-sidebar-config.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/icons/loading-dots.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\",\"display\":\"swap\"}],\"variableName\":\"fontSans\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/nextjs-toploader/dist/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-hot-toast/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/tailwind.css")